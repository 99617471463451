/* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */

var prevScrollpos = window.pageYOffset;
window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
        
  if (currentScrollPos < 50) {      
      
    $("#wrapper-navbar").removeClass('reduced');
    $("#main-content").removeClass('reduced');
      
  } else {
    $("#wrapper-navbar").addClass('reduced');
    $("#main-content").addClass('reduced');
      
  }
  prevScrollpos = currentScrollPos;
}
