function hide_banner(id) {

    $('#' + id).slideUp("slow");

    setCookie(id, 'true', 30);
}



function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}






jQuery(document).ready(function() {

    //fix loadig js lag
    jQuery('.no-js').removeClass("no-js");

    //big carousel
    jQuery('.main-carousel').slick({
        autoplay: true,
        dots: false,
        arrows: false,
        adaptiveHeight: true,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        autoplaySpeed: 2000,
        speed: 1000
    });


    $('.items-carousel').slick({
        autoplay: true,
        dots: true,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 6,
        //centerMode: true,
        autoplaySpeed: 3000,
        speed: 1000,

        responsive: [

            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]

    });



});


function copyStringToClipboard(str) {
    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
}